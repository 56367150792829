<template>
    <div class="account">
        <div class="userTitle">
            <span>{{ $t('xin-zeng') }}</span>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">{{ $t('shou-ye') }}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: adminPath }">{{ $t('ge-ren-zhong-xin') }}</el-breadcrumb-item>

                <el-breadcrumb-item :to="{ path: '/mineOrder' }">{{
                    $t('chan-pin-xu-qiu-cheng-jiao-dan')
                }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ form.id ? $t('bian-ji') : $t('xin-zeng') }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <p class="dividingLine"></p>
        <div class="main" v-loading="loading">
            <el-form
                :label-width="isChinese ? '164px' : '250px'"
                size="small"
                :model="form"
                style="max-width: 800px"
                ref="form"
                :rules="rules"
                hide-required-asterisk
            >
                <el-form-item prop="type" :label="$t('xuan-ze-xin-zeng-qing-dan-lei-xing')" :rules="rulesRequire">
                    <el-select
                        v-model="form.type"
                        :placeholder="$t('qing-xuan-ze')"
                        class="input3"
                        :disabled="!!form.id"
                    >
                        <el-option
                            v-for="item in collectTypes"
                            :key="item.key"
                            :label="$t(item.name)"
                            :value="item.key"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="name" :label="$t('chan-pin-ming-cheng')" :rules="rulesRequire">
                    <el-input
                        v-model="form.name"
                        :placeholder="$t('qing-shu-ru-chan-pin-ming-cheng')"
                        class="input2"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="brand" :label="$t('chan-pin-pin-pai')" :rules="rulesRequire">
                    <el-input
                        v-model="form.brand"
                        :placeholder="$t('qing-shu-ru-chan-pin-pin-pai-ming')"
                        class="input2"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="model" :label="$t('chan-pin-xing-hao')" :rules="rulesRequire">
                    <el-input
                        v-model="form.model"
                        :placeholder="$t('qing-shu-ru-chan-pin-xing-hao')"
                        class="input2"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="productCategoryId"
                    v-if="form.type === 'REQUIRED_PRODUCT'"
                    :label="$t('chan-pin-lei-bie')"
                    :rules="rulesRequire"
                >
                    <el-cascader
                        v-model="form.productCategoryId"
                        :props="optionProps"
                        :options="categories"
                        :placeholder="$t('qing-xuan-ze-chan-pin-lei-bie')"
                        class="input2"
                        :show-all-levels="false"
                    >
                    </el-cascader>
                </el-form-item>

                <el-form-item prop="customCategory" v-else :label="$t('chan-pin-lei-bie')" :rules="rulesRequire">
                    <el-input
                        v-model="form.customCategory"
                        :placeholder="$t('qing-shu-ru-chan-pin-lei-bie')"
                        class="input2"
                        clearable
                    ></el-input>
                </el-form-item>

                <el-form-item prop="applicationField" :label="$t('ying-yong-ling-yu')">
                    <ApplicationField
                        class="input2"
                        :ids.sync="form.applicationField"
                        :label.sync="form.customApplicationField"
                    />
                </el-form-item>
                <el-form-item prop="introduction" :label="$t('chan-pin-miao-shu')">
                    <el-input
                        type="textarea"
                        v-model="form.introduction"
                        :autosize="{ minRows: 4, maxRows: 8 }"
                        :maxlength="200"
                        :placeholder="$t('qing-jian-yao-gai-shu-chan-pin-gong-neng-200-zi-yi-nei')"
                        show-word-limit
                        class="input3"
                        resize="none"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="warning" @click="onSubmit" :loading="loading">{{ $t('ti-jiao') }}</el-button>
                    <el-button @click="onDelete" size="small" :loading="loading" type="danger" v-if="form.id">
                        {{ $t('shan-chu') }}
                    </el-button>
                    <el-button v-else plain type="info" @click="saveStorage" :loading="loading">
                        {{ $t('bao-cun') }}
                    </el-button>
                    <el-button plain type="info" @click="$router.go(-1)">{{ $t('fan-hui') }}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { productListType } from '../../utils/AppState';
import formPage from '../../mixins/formPage';
import ProductCategory from '../../components/select/ProductCategory';
import ApplicationField from '../../components/select/ApplicationField';
export default {
    name: 'orderEdit',
    mixins: [formPage],
    data() {
        return {
            form: {
                type: ''
            },
            optionProps1: {
                value: 'id',
                label: 'chName',
                children: 'children',
                multiple: true,
                emitPath: false
            },
            categories: [],
            tags: [],
            rules: {
                applicationField: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value || this.form.customApplicationField) {
                                callback();
                            } else {
                                callback(' ');
                            }
                        },
                        trigger: 'blur'
                    }
                ]
            }
        };
    },
    mounted() {
        if (this.$route.query.id) {
            this.loading = true;
            this.$http
                .get('/productList/get/' + this.$route.query.id)
                .then(res => {
                    delete res.productCategory;
                    this.form = res;
                })
                .finally(() => {
                    this.loading = false;
                });
        } else if (window.localStorage.getItem('orderEdit_' + this.$store.state.userInfo.id)) {
            const orderEdit = JSON.parse(window.localStorage.getItem('orderEdit_' + this.$store.state.userInfo.id));
            this.form = orderEdit.form;
        } else if (this.$route.query.type) {
            this.form.type = this.$route.query.type;
        }
        console.log(this.$route.query.type);

        this.$http
            .get('/productCategory/allList')
            .then(res => {
                this.categories = this.getTreeData(res, 0);
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
    },
    methods: {
        submit() {
            const form = { ...this.form };

            if (!form.id) {
                form.userId = this.$store.state.userInfo.id;
                window.localStorage.removeItem('orderEdit_' + this.$store.state.userInfo.id);
            }

            this.$http
                .post('/productList/save', form, { body: 'json' })
                .then(res => {
                    this.$message.success(form.id ? this.$t('xiu-gai-cheng-gong') : this.$t('xin-zeng-cheng-gong'));
                    this.$router.go(-1);
                })
                .catch(e => {
                    this.$message.warning(e.error);
                });
        },
        onDelete() {
            this.$alert(this.$t('shan-chu-jiang-wu-fa-hui-fu-que-ren-yao-shan-chu-mo'), this.$t('jing-gao'), {
                type: 'error'
            })
                .then(() => {
                    return this.$http.post(`/productList/del/${this.form.id}`);
                })
                .then(() => {
                    this.$message.success(this.$t('shan-chu-cheng-gong'));
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error(e.error);
                    }
                });
        },
        saveStorage() {
            const orderEdit = {
                form: this.form
            };
            window.localStorage.setItem('orderEdit_' + this.$store.state.userInfo.id, JSON.stringify(caseEdit));
            this.$message.success(this.$t('bao-cun-cheng-gong'));
            history.back();
        },
        getTreeData(data, num = 0, disabledNum = 2) {
            // 循环遍历json数据
            for (let i = 0; i < data.length; i++) {
                if (num < disabledNum) {
                    data[i].disabled = true;
                }
                if (data[i].children.length < 1) {
                    // children若为空数组，则将children设为undefined
                    data[i].children = undefined;
                } else {
                    // children若不为空数组，则继续 递归调用 本方法
                    this.getTreeData(data[i].children, num + 1, disabledNum);
                }
            }
            return data;
        }
    },
    computed: {
        collectTypes() {
            return [...productListType]
                .map(item => {
                    return {
                        key: item[0],
                        name: item[1]
                    };
                })
                .filter(item => {
                    if (this.isVendor && !this.form.id && item.key === 'OWN_PRODUCT') {
                        return false;
                    } else {
                        return true;
                    }
                });
        },
        optionProps() {
            return {
                value: 'id',
                label: this.$t('chname'),
                children: 'children',
                multiple: false,
                emitPath: false,
                checkStrictly: true,
                expandTrigger: 'hover'
            };
        }
    },
    components: {
        ProductCategory,
        ApplicationField
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';

.userTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main {
    padding: 20px;
}
.el-button {
    padding: 0 20px;
    line-height: 40px;
    min-width: 120px;
    margin-top: 32px;
}

.el-form {
    margin: 20px 0;
}
.el-button--info {
    background: transparent;
    min-width: 120px;
}
.userTitle {
    border: none;
}
.dividingLine {
    height: 1px;
    background-color: #dcdfe6;
    margin: 0 auto;
    width: 97%;
}
</style>
