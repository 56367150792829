<template>
    <!-- 应用领域 选择组件 -->
    <el-select
        v-model="value1"
        multiple
        filterable
        allow-create
        default-first-option
        :placeholder="$t('qing-xuan-ze-huo-zhe-shou-dong-shu-ru-wen-zi-hou-an-hui-che-tian-jia')"
        @change="select"
    >
        <el-option v-for="item in list" :key="item.id" :label="getName(item)" :value="`ids_${item.id}`"> </el-option>
    </el-select>
</template>
<script>
import { delChild, getInfoByKey } from '../../utils/Array';
export default {
    name: 'ApplicationFieldSelect',
    props: {
        ids: {
            type: Array,
            default: () => {
                return [];
            }
        },
        label: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            list: [],
            value1: []
        };
    },
    watch: {
        ids() {
            console.log(this.ids);
            if (this.ids.length > 0 && this.nowIds.length === 0) {
                this.value1 = [
                    ...this.value1,
                    ...[...this.ids].map(item => {
                        return `ids_${item}`;
                    })
                ];
            }
        },
        label() {
            if (this.label && !this.nowLable) {
                this.value1 = [...this.value1, ...this.label.split(',')];
            }
        },
        nowIdInfos() {
            this.$emit('update:applicationField', this.nowIdInfos);
        }
    },
    computed: {
        nowIds() {
            var list = [...this.value1];
            return list
                .filter(item => {
                    return item.indexOf('ids_') === 0;
                })
                .map(item => {
                    return Number(item.replace(/ids_/, ''));
                });
        },
        nowLable() {
            var list = [...this.value1];
            return list
                .filter(item => {
                    return item.indexOf('ids_') === -1;
                })
                .map(item => {
                    return item;
                })
                .join(',');
        },
        nowIdInfos() {
            const ids = [...this.nowIds];
            return ids.map(item => {
                return getInfoByKey(this.list, item);
            });
        }
    },
    mounted() {
        this.$http
            .get('/productTag/application')
            .then(res => {
                this.list = delChild(res);
                setTimeout(() => {
                    console.log(this.label);
                }, 500);
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
    },
    methods: {
        select(list) {
            this.$nextTick(() => {
                this.$emit('update:ids', this.nowIds);
                this.$emit('update:label', this.nowLable);
            });
        }
    }
};
</script>
<style lang="less" scoped></style>
